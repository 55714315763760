import React, { useCallback, useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useHistory } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';

import OrdersFinished from './OrdersFinished';
import OrdersInProgress from './OrdersInProgress';

import { Container, Content, Header, Main, ButtonOrders, Nav } from './styles';
import { useAuth } from '../../../context/auth';
import { useBaskets } from '../../../context/BasketsContext';
import CashbackBanner from '../../../components/ClubRegisterComponents/CashbackBanner';
import bannerCashback1 from '../../../assets/images/bannerCashback1.png';
import { ModalCTACashback } from '../../../components/ModalCTACashback';
import api from '../../../services/api';

const Orders = () => {
  const history = useHistory();
  const {
    restaurantName,
    websocketManager,
    isScheduling,
    userPhone,
    restaurantId,
    modalCTA,
    setModalCTA,
  } = useAuth();
  const { baskets, fetchData, setBaskets } = useBaskets();
  const [loadingClube, setLoadingClube] = useState(false);
  const [clubeClient, setClubeClient] = useState(null);
  const [clubeFound, setClubeFound] = useState(true);

  const [defaulIndexState, setDefaultIndexState] = useState(0);
  function handleNavigate() {
    history.push(`/${restaurantName}`);
  }

  const [selectedTab, setSelectedTab] = useState(
    isScheduling.toString() === 'true' ? 1 : defaulIndexState,
  );

  const getInfoCliente = useCallback(async phone => {
    setLoadingClube(true);
    try {
      let handlePhone = phone.replace(' ', '').replace('-', '');
      const response = await api.get(
        `/public/clube/client/${handlePhone}/${restaurantId}`,
      );
      setClubeClient(response.data);
      if (response.data.notFound) {
        setClubeFound(false);
      }
    } catch (err) {
      console.log(err);
    }
    setLoadingClube(false);
  }, []);

  useEffect(() => {
    getInfoCliente(userPhone);
  }, []);

  useEffect(() => {
    if (websocketManager) {
      websocketManager.addMessageCallback(2, data => {
        console.log('ClientOrders: socket new data', data);
        const { basket_id, basket_status } = data.item;
        if (data.type === 'status-changed') {
          if (basket_status === 'finished') {
            setDefaultIndexState(2);
          }

          setBaskets(state => {
            const newOrders = [...state];

            const idx = newOrders.findIndex(s => s.id === basket_id);

            if (idx !== -1) {
              newOrders[idx].order_status = basket_status;
            }

            return newOrders;
          });
          // fetchData();
        }
      });
    }
  }, [websocketManager, setBaskets, setDefaultIndexState]);

  const showCashbackBanner =
    !loadingClube && !!clubeClient && !clubeClient.clientExist && clubeFound;

  return (
    <Container>
      <Content>
        <Header>
          <h1>Meus Pedidos</h1>
        </Header>

        <Main>
          {showCashbackBanner && (
            <>
              <CashbackBanner
                style={{
                  width: 'calc(100% - 20px)',
                  margin: '0 auto',
                }}
                src={bannerCashback1}
                onClick={() => history.push('/club/register')}
              >
                <h2>Quer economizar na próxima compra?</h2>
                <p>Cadastre-se agora para começar a receber cashback!</p>
                <button>QUERO ECONOMIZAR</button>
              </CashbackBanner>
              <ModalCTACashback isOpen={modalCTA} setIsOpen={setModalCTA} />
            </>
          )}
          {isScheduling.toString() === 'true' && (
            <Tabs defaultIndex={1} onSelect={idx => setSelectedTab(idx)}>
              <Nav>
                <TabList>
                  <Tab
                    style={{ textDecoration: selectedTab === 0 && 'underline' }}
                  >
                    Em andamento
                  </Tab>
                  <Tab
                    style={{ textDecoration: selectedTab === 1 && 'underline' }}
                  >
                    Agendados
                  </Tab>
                  <Tab
                    style={{ textDecoration: selectedTab === 2 && 'underline' }}
                  >
                    Finalizadas
                  </Tab>
                </TabList>
              </Nav>

              <TabPanel>
                <OrdersInProgress ordersInProgress={baskets} />
              </TabPanel>

              <TabPanel>
                <OrdersInProgress schedulingList ordersInProgress={baskets} />
              </TabPanel>

              <TabPanel>
                <OrdersFinished ordersFinished={baskets} />
              </TabPanel>
            </Tabs>
          )}

          {defaulIndexState === 0 && (
            <Tabs defaultIndex={0} onSelect={idx => setSelectedTab(idx)}>
              <Nav>
                <TabList>
                  <Tab
                    style={{ textDecoration: selectedTab === 0 && 'underline' }}
                  >
                    Em andamento
                  </Tab>
                  <Tab
                    style={{ textDecoration: selectedTab === 1 && 'underline' }}
                  >
                    Agendados
                  </Tab>
                  <Tab
                    style={{ textDecoration: selectedTab === 2 && 'underline' }}
                  >
                    Finalizadas
                  </Tab>
                </TabList>
              </Nav>

              <TabPanel>
                <OrdersInProgress ordersInProgress={baskets} />
              </TabPanel>

              <TabPanel>
                <OrdersInProgress schedulingList ordersInProgress={baskets} />
              </TabPanel>

              <TabPanel>
                <OrdersFinished ordersFinished={baskets} />
              </TabPanel>
            </Tabs>
          )}

          {defaulIndexState === 2 && (
            <Tabs defaultIndex={2} onSelect={idx => setSelectedTab(idx)}>
              <Nav>
                <TabList>
                  <Tab
                    style={{ textDecoration: selectedTab === 0 && 'underline' }}
                  >
                    Em andamento
                  </Tab>
                  <Tab
                    style={{ textDecoration: selectedTab === 1 && 'underline' }}
                  >
                    Agendados
                  </Tab>
                  <Tab
                    style={{ textDecoration: selectedTab === 2 && 'underline' }}
                  >
                    Finalizadas
                  </Tab>
                </TabList>
              </Nav>

              <TabPanel>
                <OrdersInProgress ordersInProgress={baskets} />
              </TabPanel>

              <TabPanel>
                <OrdersInProgress schedulingList ordersInProgress={baskets} />
              </TabPanel>

              <TabPanel>
                <OrdersFinished ordersFinished={baskets} />
              </TabPanel>
            </Tabs>
          )}
        </Main>

        <ButtonOrders onClick={handleNavigate}>
          <FiArrowLeft />
          Voltar para Cardápio
        </ButtonOrders>
      </Content>
    </Container>
  );
};

export default Orders;
